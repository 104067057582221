<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card card-primary">
                            <ValidationObserver v-slot="{ invalid }">
                                <form @submit="saveForm">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group col-sm-6">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="date_format">Date Format *</label>
                                                    <select class="form-control" id="date_format" v-model="formData.date_format">
                                                        <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                                                        <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                                                        <option value="YYYY/MM/DD">YYYY/MM/DD</option>
                                                    </select>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="invoice_thermal_print">Invoice Thermal Print? *</label>
                                                    <select class="form-control" id="invoice_thermal_print" v-model="formData.invoice_thermal_print">
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="currency">Currency *</label>
                                                    <input type="text" class="form-control" id="currency" placeholder="Enter your currency" v-model="formData.currency" required>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group col-sm-6">
                                                <ValidationProvider v-slot="{ errors }" rules="required">
                                                    <label for="currency_prefix">Currency as Prefix? *</label>
                                                    <select class="form-control" id="currency_prefix" v-model="formData.currency_prefix">
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer">
                                        <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Update</button>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>


            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../components/app/common/BreadCrumb";
    import Vue from 'vue'

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });

    extend('positiveFPN', value => {
        let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
        return value >= 0 && regexFloatingPoint.test(value)? true: 'This field must be a positive number';
    });
    import {CompanyService} from "@/services/company-service";
    let companyService = new CompanyService();
    import {UserService} from "@/services/user-service";
    let userService = new UserService();
    export default {
        name: 'Settings_view',
        data: function(){
            return {
                pageName: 'Settings',
                segments: [
                    { link: false, text: 'Settings' },
                ],
                countries: [],
                upload_image: null,
                formData: companyService.formDataCompany
            }
        },
        props: {},
        components: {
            BreadCrumb, ValidationObserver, ValidationProvider
        },
        computed: {

        },
        methods:{
            async loadSavedData(){
                let loader = this.$loading.show();
                let response = await companyService.getCompany();
                if (response.isSuccess) {
                    this.formData = response.company;
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();

                this.formData['mode'] = 'settings';
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                companyService.formDataCompany = this.formData;
                let response = await companyService.saveCompany();
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    let userInitResponse = await userService.userInit();
                    if(userInitResponse.isSuccess){
                        await this.loadSavedData();
                    }
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            }
        },
        mounted() {
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
